import moment from "moment";

export function formatter(value, event) {
  const fragments = value.trim().split("/");

  let newValue = value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");

  let month = "";
  let day = "";
  let year = "";

  if (fragments.length == 3) {
    month = fragments[0];
    day = fragments[1];
    year = fragments[2];
  } else {
    month = newValue.substring(0, 2);
    day = newValue.substring(2, 4);
    year = newValue.substring(4, 8);
  }

  if (month.length == 1 && Number(month) > 1) {
    month = "0" + month;
  }

  if (month.length == 2 && Number(month) > 12) {
    month = "12";
  }

  if (day.length == 1 && Number(day) > 3) {
    day = "0" + day;
  }
  if (day.length == 2 && Number(day) > 31) {
    day = day.substring(1, 1);
  }

  newValue = month + day + year;

  if (!event.data) {
    return value;
  }

  const dayOrMonth = (index) => index % 2 === 1 && index < 4;

  // on delete key.

  return newValue
    .split("")
    .map((v, i) => (dayOrMonth(i) ? v + "/" : v))
    .join("");
}

export function getAge(date, formatter) {
  return moment().diff(moment(date, formatter), "years", false);
}

export function getDays(date, formatter) {
  return moment().diff(moment(date, formatter), "days", false);
}
