export function saltarBeneficiarios(observations) {
  return observations && (observations.includes("PA04") || observations.includes("PA05"));
}

export function saltarDependientes(observations) {
  return (
    observations &&
    (observations.includes("PA01") ||
      observations.includes("PA06") ||
      observations.includes("PA07"))
  );
}

export function viewACH(observations) {
  return observations && !observations.includes("PA01");
}

export function planType(observations) {
  if (observations.includes("PA01")) {
    return ["V"];
  }
  if (observations.includes("PA02")) {
    return ["V", "G"];
  }
  if (observations.includes("PA03")) {
    return ["V", "G", "D"];
  }
  if (observations.includes("PA04")) {
    return ["G"];
  }
  if (observations.includes("PA05")) {
    return ["G", "D"];
  }
  if (observations.includes("PA06")) {
    return ["V", "A"];
  }
  if (observations.includes("PA07")) {
    return ["A"];
  }
  if (observations.includes("PA08")) {
    return ["A", "G"];
  }

  return [];
}
