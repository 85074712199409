<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">
                Beneficiarios para Seguro de Vida y Anexos (En caso su póliza considere un seguro de
                Vida)
              </h6>
            </template>

            <table class="table-one">
              <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Número de Identidad</th>
                <th>Fecha Nacimiento (mm/dd/aaaa)</th>
                <th>Parentesco</th>
                <th>Porcentaje</th>
                <th style="width: 70px"></th>
              </tr>
              <tr v-for="(item, index) in form.beneficiarios" v-bind:key="index">
                <td style="width: 15%">
                  <b-form-input
                    type="text"
                    v-model="item.name"
                    :state="getValidationName(index)"
                  ></b-form-input>
                </td>
                <td style="width: 15%">
                  <b-form-input
                    type="text"
                    v-model="item.lastName"
                    :state="getValidationLastName(index)"
                  ></b-form-input>
                </td>
                <td style="width: 20%">
                  <b-form-input
                    type="text"
                    v-model="item.document"
                    :state="getValidationDocument(index)"
                  ></b-form-input>
                </td>

                <td style="width: 7%">
                  <DateGrid
                    :form="item"
                    name="birthDay"
                    :state="getValidationBirthDay(index)"
                  ></DateGrid>
                </td>

                <td style="width: 15%">
                  <b-form-select
                    v-model="item.relation"
                    :options="control.TIPOS_BENIFICARIOS"
                    :state="getValidationRelation(index)"
                  ></b-form-select>
                </td>

                <td style="width: 10%">
                  <validation-provider name="label">
                    <b-form-input
                      type="number"
                      min="0"
                      max="100"
                      v-model="item.percentage"
                      :state="getValidationPercentage(index)"
                    ></b-form-input>
                  </validation-provider>
                </td>

                <td style="width: 5%">
                  <b-btn
                    v-if="!rowEmpty(index)"
                    pill
                    variant="outline-danger"
                    @click="limpiar(index)"
                    >Borrar</b-btn
                  >
                </td>
              </tr>
            </table>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button @click="previous" variant="secondary">Anterior</b-button>
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import DateGrid from "../../../components/controls/DateGrid.vue";
import { TIPOS_BENIFICARIOS } from "../../../data/ecuador/Selects";
import { conyugeRepetido } from "../../../helpers/beneficiariosHelper";
import { getAge, getDays } from "../../../helpers/dateHelper";
import { saltarDependientes } from "../../../helpers/reglasEcuador";

const edadMaximaConyuge = 99;
const edadMinimaConyuge = 18;
const edadMaximaHijo = 24;

export default {
  name: "Home",
  components: { DateGrid },
  data() {
    return {
      messagge: "",
      control: { LanguageDisabled: 1, TIPOS_BENIFICARIOS },
      form: {},
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted() {
    this.$store.dispatch("app/loading", true);
    const { form } = this.$store.state.wizard;
    console.log("Formulario de beneficiarios:", this.form);
    if (!form) {
      this.$router.push("/");
    }
    this.form = form;
    this.$store.dispatch("app/loading", false);
  },
  methods: {
    previous() {
      const form = { ...this.form };

      this.$store
        .dispatch("wizard/save", { index: 1, form })
        .then(this.$router.push("/form/ecuador-1.html"));
    },
    next() {
      const form = { ...this.form };

      if (this.tableEmpty()) {
        this.$bvToast.toast("Por favor cargue al menos un beneficiario", {
          title: "Informacion incompleta",
          variant: "danger",
          solid: true,
        });
        return false;
      }

      this.form.beneficiarios = this.form.beneficiarios.map((element) => {
        if (element.birthDay) {
          var years = moment().diff(element.birthDay, "years", false);
          return { ...element, age: years };
        } else {
          return element;
        }
      });

      if (this.validation()) {
        const saltar_dependientes = saltarDependientes(this.form.observations);

        if (saltar_dependientes === true)
          this.$store
            .dispatch("wizard/save", { index: 3, form })
            .then(this.$router.push("/form/ecuador-4.html"));
        else
          this.$store
            .dispatch("wizard/save", { index: 2, form })
            .then(this.$router.push("/form/ecuador-3.html"));
      } else {
        this.$bvToast.toast("Por favor controle la información cargada en beneficiarios ", {
          title: "Información incompleta",
          variant: "danger",
          solid: true,
        });
      }
    },
    validation() {
      if (conyugeRepetido(this)) {
        return false;
      }

      let ok = true;
      for (let i = 0; i < this.form.beneficiarios.length; i++) {
        if (!this.rowEmpty(i))
          ok =
            ok &&
            this.getValidationName(i) &&
            this.getValidationLastName(i) &&
            this.getValidationDocument(i) &&
            this.getValidationBirthDay(i) &&
            this.getValidationRelation(i) &&
            this.getValidationPercentage(i);
      }
      return ok;
    },
    tableEmpty() {
      for (let i = 0; i < this.form.beneficiarios.length; i++) {
        if (!this.rowEmpty(i)) {
          return false;
        }
      }
      return true;
    },
    rowEmpty(index) {
      return (
        this.form.beneficiarios[index].name == "" &&
        this.form.beneficiarios[index].lastName == "" &&
        this.form.beneficiarios[index].document == "" &&
        this.form.beneficiarios[index].birthDay == "" &&
        this.form.beneficiarios[index].relation == "" &&
        this.form.beneficiarios[index].percentage == ""
      );
    },
    limpiar(index) {
      this.form.beneficiarios[index].name = "";
      this.form.beneficiarios[index].lastName = "";
      this.form.beneficiarios[index].document = "";
      this.form.beneficiarios[index].birthDay = "";
      this.form.beneficiarios[index].relation = "";
      this.form.beneficiarios[index].percentage = "";
      this.form.beneficiarios[index].age = "";
    },
    getValidationName(index) {
      const state = this.rowEmpty(index) || this.form.beneficiarios[index].name != "";

      if (state && this.form.beneficiarios[index].name == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationLastName(index) {
      const state = this.rowEmpty(index) || this.form.beneficiarios[index].lastName != "";

      if (state && this.form.beneficiarios[index].lastName == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationDocument(index) {
      const state =
        (this.rowEmpty(index) || this.form.beneficiarios[index].document != "") &&
        this.form.beneficiarios[index].document.length <= 10;

      if (state && this.form.beneficiarios[index].document == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationBirthDay(index) {
      let state = this.rowEmpty(index) || this.form.beneficiarios[index].birthDay != "";

      const birthDay = this.form.beneficiarios[index].birthDay;

      if (this.form.beneficiarios[index].birthDay != "") {
        const edad = getAge(birthDay, "MM/DD/YYYY");

        const days = getDays(birthDay, "MM/DD/YYYY");

        if (this.form.beneficiarios[index].relation == "HIJA(O)")
          state = state && days > 0 && edad <= edadMaximaHijo;

        if (this.form.beneficiarios[index].relation == "ESPOSA(O)")
          state = state && edad >= edadMinimaConyuge && edad <= edadMaximaConyuge;
      }

      if (state && this.form.beneficiarios[index].birthDay == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationPercentage(index) {
      const valor = Number(this.form.beneficiarios[index].percentage);
      const total = this.form.beneficiarios.reduce(
        (total, current) => total + Number(current.percentage),
        0
      );

      const state = this.rowEmpty(index) || (valor >= 1 && valor <= 100);

      if (state && this.form.beneficiarios[index].percentage == "") {
        return null;
      } else {
        return state && total == 100;
      }
    },
    getValidationRelation(index) {
      const state =
        this.rowEmpty(index) ||
        TIPOS_BENIFICARIOS.map((e) => e.value).includes(this.form.beneficiarios[index].relation);

      if (state && this.form.beneficiarios[index].relation == "") {
        return null;
      } else {
        return state;
      }
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 1600px;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}

.td-button {
  width: 80px;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
</style>
